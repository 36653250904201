import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { AdsGETApi, AdsGETApiAuth, AdsPOSTApi, ExamBlockPlaceHolder, NodePOSTApiAuth } from '../../../Constant/api';
import { slugs } from '../../../Constant/slugs';
import { logout } from '../../../redux/action';
import NotFound from '../../NotFound';

const slug = {
  "ubt": "UBT",
  "cbt-chemical": "CBT CHEMICAL",
  "cbt-electronics-electricity": "CBT ELECTRONIC ELECTRCITY",
  "cbt-food-related": "CBT FOOD RELATED",
  "cbt-mechinery-molding": "CBT MACHINERY,MOLDING",
  "cbt-metal": "CBT METAL",
  "cbt-pulp-paper-wood": "CBT PULP PAPER WOOD",
  "cbt-rubber": "CBT RUBBER",
  "cbt-textile-sewing": "CBT TEXTILE SEWING"
}
export default function StartCourse(props) {
  // const { ChapterData } = props;
  const dispatch = useDispatch()
  const LoginState = useSelector((state) => state.LoginState);
  const [ChapterData, setChapterData] = React.useState([])
  const [classDetails, setClassDetails] = React.useState({})
  const [choose, setChoose] = React.useState(0)
  const [NotFoundData, setNotFound] = React.useState(false)
  const [ExamFetch, setExamFetch] = React.useState(false);
  const [PreviewUrl, setPreviewUrl] = React.useState("")
  const [PreviewType, setPreviewType] = React.useState("")
  const [PreviewObj, setPreviewObj] = React.useState({})
  const [PreviewPressed, setPreviewPressed] = React.useState(false)
  const fetchExam = (perPage, verifiedExamList) => {
    AdsGETApiAuth({
      ...perPage,
    }, slugs.db_slug_book)
      .then((res) => {
        if (res.data.length !== 0)
          setClassDetails(res.data[0])
        setChoose(1)
      })
      .catch(err => {
        console.log(err)
        console.log(err.response.data)
        if (err.message.includes("Request failed with status code 500"))
          dispatch(logout())
      })
      .finally(() => {
        setExamFetch(true)
      })
  }
  const location = useLocation()
  const history = useHistory()
  const [Code, setCode] = React.useState("")
  const [CodeGet, setCodeGet] = React.useState("")
  const [StartPressed, setStartPressed] = React.useState(true)

  useEffect(() => {
    var type;
    const codeget = localStorage.getItem("exam_code")
    setCodeGet(codeget)
    type = location.pathname.slice(slugs.start_course.length + 1)
    fetchExam({
      slug: type
    },
    )

  }, [])

  return (
    <div style={{ margin: "3px", minHeight: "100vh" }}>
      {ExamFetch &&
        <>
          {NotFoundData ?
            <>
              <NotFound />
            </>

            :
            <>
              {classDetails.meta.free !== "Gratis" &&
                <>
                  {classDetails.meta.exam_code !== CodeGet && StartPressed &&
                    <div className="modal">
                      <div class="modal-dialog" role="document">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title">Exam Code</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                              onClick={() => {
                                history.goBack()
                              }}
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div class="modal-body">
                            <p>Please Enter The Code to start.</p>
                            <input className="input-common"
                              value={Code} placeholder="Exam Code"
                              onChange={(event) => { setCode(event.target.value) }}
                            />
                          </div>
                          <div class="modal-footer">
                            {Code == classDetails.meta.exam_code ?
                              <button type="button" class="btn btn-primary"
                                onClick={() => {
                                  localStorage.setItem("exam_code", Code)
                                  setStartPressed(false)
                                }}
                              >Open</button>
                              :
                              <div type="button" class="btn btn-dark">Start</div>
                            }
                            <button type="button" class="btn btn-secondary" data-dismiss="modal"
                              onClick={() => {
                                history.goBack()
                              }}
                            >Cancel</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </>
              }
              {!PreviewPressed ?
                <>
                  {choose == 1 &&
                    <div className="row">
                      <div className="col-1"></div>
                      <div className="col-10 form-view mt-3">
                        <div className="row" style={{ margin: "10px" }}>
                          <h2>{classDetails.title.rendered}</h2>
                          <div className="row" style={{ marginLeft: "10px" }}>
                            {Object.values(JSON.parse(classDetails.meta.course_content)).map((item2, index2) => (
                              // {ChapterData.map((item, index) => (
                              <div key={index2} className="col-sm-4 col-12">
                                <div className="shadow" style={{ cursor: "pointer", backgroundColor: "#fff", margin: "10px 10px 0 0 ", padding: "10px" }}
                                  onClick={() => {
                                    setPreviewPressed(true)
                                    setPreviewObj(item2)
                                    setPreviewType(item2.chapter_type)

                                    if (item2.chapter_type == "PDF")
                                      setPreviewUrl(item2.pdf_url)
                                    else
                                      if (item2.youtube_id == "")
                                        setPreviewUrl(item2.video_link)
                                      else
                                        setPreviewUrl(`https://www.youtube.com/embed/${item2.youtube_id}`)
                                  }}
                                >
                                  <h4>{item2.chapter_number}. {item2.title}</h4>
                                  <div>{item2.description}</div>
                                  <div>{item2.course_type}</div>
                                  <div>{console.log(JSON.stringify(item2))}</div>
                                  <div style={{ padding: 10 }}>
                                    <span style={{ border: "1px solid black", borderRadius: 5, padding: "5px 15px" }}> View</span>
                                  </div>
                                  <div style={{ marginLeft: "40px", fontSize: "12px" }}>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </>
                :
                <div>
                  <div style={{ padding: 10 }}
                    onClick={() => { setPreviewPressed(false) }}
                  >
                    <span style={{ cursor: "pointer", border: "1px solid black", borderRadius: 5, padding: "5px 15px" }}><i className='fa fa-chevron-left' />  All Chapters</span>
                  </div>
                  <div style={{ minHeight: "85vh", overflowY: "scroll" }}>
                    <iframe
                      style={{ width: "100%", height: "85vh" }}
                      src={PreviewUrl
                      }>
                    </iframe>
                    {Object.values(JSON.parse(PreviewObj.audio_list)).length !== 0 &&
                      <>
                        <div className="row">

                          <span className="col-6" style={{ fontSize: "16px", fontWeight: "bold" }}>
                            Audio Resources
                          </span>
                          <div className="col-6" style={{ textAlign: "right" }}>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                              onClick={() => { setPreviewPressed(false) }}
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                        </div>

                        <div>
                          <div style={{ whiteSpace: "nowrap", overflowX: "scroll" }}>
                            <div style={{ overflow: "auto", display: "inline", }}>
                              {Object.values(JSON.parse(PreviewObj.audio_list)).map((item, index) => (
                                <div key={index} style={{ display: "inline-block" }}>
                                  {/* {item.audio_url} */}
                                  {item.audio_url.includes("https") &&
                                    <div className="shadow" style={{ cursor: "pointer", backgroundColor: "#fff", margin: "5px", padding: "10px" }}
                                      onClick={() => {
                                        // setAudioUrl(item)
                                      }}
                                    >
                                      <center>
                                        <div onClick={() => { }}>
                                          {/* <img style={{ width: "40px" }} src={"https://api.dreamkoreaubttest.com/wp-content/uploads/2023/11/543227.png"} />
                                  </div> */}
                                          <audio controls autoplay>
                                            <source src={`${item.audio_url}`} type="audio/mpeg" />
                                            <source src={`${item.audio_url}`} type="audio/mp3" />
                                            <source src={`${item.audio_url}`} type="audio/wav" />
                                            <source src={`${item.audio_url}`} type="audio/ogg" />
                                            Your browser does not support the audio element.
                                          </audio>
                                        </div>

                                        {item.audio_url.slice(54).replace(".mp3", "")}
                                      </center>
                                    </div>
                                  }
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </>
                    }
                  </div>
                </div>
              }
            </>
          }
        </>
      }
    </div >
  )
}

