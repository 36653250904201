import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { AdsGETApi, AdsGETApiAuth, AdsPOSTApi, signInImage } from '../../../Constant/api';
import { slugs } from '../../../Constant/slugs';
import { logout } from '../../../redux/action';
const typeArray = {
  "book": "Book",
  "videos": "Videos",
  "skill-test": "Materi Skill Test",
}
export default function AllBooksList() {
  const history = useHistory()
  const [AllExamList1, setAllExamList1] = React.useState([]);
  const [reload, setReload] = React.useState(false)
  const [pagesLength, setPagesLength] = React.useState("1");
  const [pages, setPages] = React.useState(1);
  const dispatch = useDispatch()
  const LoginUser = useSelector((state) => state.LoginState);

  const GetAllProjects = (type) => {
    setReload(true)
    AdsGETApiAuth({
      per_page: 100,
      _fields: "id,title,slug,meta,status",
      "filter[meta_query][1][key]": "book_type",
      "filter[meta_query][1][value][0]": `${typeArray[type]}`,
      "filter[meta_query][1][compare]": "=",
    }, slugs.db_slug_book)
      .then((res) => {
        setAllExamList1(res.data)
        setReload(false)
        console.log(res.data.length)
      })
      .catch(err => {
        console.log(err)
        console.log(err.response.data)
        if (err.message.includes("Request failed with status code 500"))
          dispatch(logout())
      })
  }
  const location = useLocation()
  useEffect(() => {
    // fetchExam(setAllExamList1, {
    //   per_page: 100, order: "asc",
    //   _fields: "id,title,slug,meta",
    // })
    // console.log(location.pathname.slice(11))
    var type;
    type = location.pathname.slice(slugs.book_list_user.length + 1) == "" ? "book" : location.pathname.slice(slugs.book_list_user.length + 1)
    GetAllProjects(type)
    return history.listen((location) => {
      if (location.pathname.includes(slugs.book_list_user)) {
        type = location.pathname.slice(slugs.book_list_user.length + 1) == "" ? "book" : location.pathname.slice(slugs.book_list_user.length + 1)
        window.scrollTo(0, 0)
        GetAllProjects(type)
      }
    })
  }, [])
  var type = location.pathname.slice(slugs.book_list_user.length + 1) == "" ? "book" : location.pathname.slice(slugs.book_list_user.length + 1)

  return (
    <div>
      <div className="row">
        <div className="col-1"></div>
        <div className="col-10 form-view mt-3">
          <div className="row" style={{ margin: "10px" }}>
            <h2>All {typeArray[type]}</h2>
            {AllExamList1.map((item, index) => (
              <div key={index} className="col-lg-3 col-md-4 col-6 center" style={{ padding: "0 5px" }}>
                <ExamBlock item={item} examSlug={slugs.start_exam} />
              </div>
            ))}
            {AllExamList1.length == 0 &&
              <div style={{ padding: '10px' }}>
                No Books Available Right now
              </div>
            }
          </div>
        </div>
      </div>

      {reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }

    </div>
  )
}

const ExamBlock = ({ item, examSlug }) => {
  const location = useLocation();
  const [Code, setCode] = React.useState("")
  const [StartPressed, setStartPressed] = React.useState(false)
  return (
    <div style={{
      padding: "5px", margin: "5px 0px", border: "1.5px solid #bbb",
      borderRadius: "5px",
    }}>
      {item.meta.featured_image !== "" ?
        <img className="col-12" src={item.meta.featured_image}
          style={{ objectFit: 'contain', height: "100px", borderRadius: "3px" }} />
        :
        <img className="col-12"
          src={signInImage}
          style={{ objectFit: 'contain', height: "100px", borderRadius: "3px" }} />
      }
      <br />
      <div>
        {item.title.rendered}&nbsp;
      </div>
      {item.meta.free == "Gratis" ?
        <Link to={`${slugs.start_course}/${item.slug}`}>
          <div style={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
            <div onClick={() => { }} className="sign-in-button-1 col-8 ">
              <div style={{ color: "#fff" }}>
                Open
              </div>
            </div>

          </div >
        </Link>
        :
        <div style={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
          <div className="sign-in-button-1 col-8 "
            onClick={() => { setStartPressed(true) }}
          >
            <div style={{ color: "#fff" }}>
              Enter Code
            </div>
          </div>
        </div>
      }
      {/* {Code == item.meta.exam_code ?
       
        :
} */}
      {
        StartPressed &&
        <div className="modal">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Exam Code</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                  onClick={() => { setStartPressed(false) }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <p>Please Enter The Weekly Exam Code to start with your exam.</p>
                <input className="input-common"
                  value={Code} placeholder="Exam Code"
                  onChange={(event) => { setCode(event.target.value) }}
                />
              </div>
              <div class="modal-footer">
                {Code == item.meta.exam_code ?
                  <Link to={`${slugs.start_course}/${item.slug}`}>
                    <button type="button" class="btn btn-primary"
                      onClick={() => {
                        localStorage.setItem("exam_code", Code)
                      }}
                    >Open</button>
                  </Link>
                  :
                  <div type="button" class="btn btn-dark">Open</div>
                }
                <button type="button" class="btn btn-secondary" data-dismiss="modal"
                  onClick={() => { setStartPressed(false) }}
                >Cancel</button>
              </div>
            </div>
          </div>
        </div>
      }
    </div >
  )

}