import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { slugs } from '../../Constant/slugs';
import { SignINLogo } from '../../Constant/api';

export default function Home() {
  const dispatch = useDispatch()

  return (
    <div style={{ height: "100vh", backgroundColor: "#fff", marginTop: 10 }}>
      <div className="row">
        <div style={{ margin: "10px" }} className="center">
          <img
            src={SignINLogo}
            style={{ height: "30vh", width: "30vh", objectFit: "contain" }}
          />
        </div>
        <div className="row" >
          <Link to={`${slugs.exam}/ubt`} className="col-4 center" style={{ padding: "20px" }}>
            <img
              className="home-icons"
              src="https://api.widihakwon.com/wp-content/uploads/2024/03/examkoreana.png"
            />
            <div className="home-fonts">
              UBT UMUM
            </div>
          </Link>
          <Link to={`${slugs.exam}/cbt`} className="col-4 center" style={{ padding: "20px" }}>
            <img
              className="home-icons"
              src="https://api.widihakwon.com/wp-content/uploads/2024/03/examkoreana.png"
            />
            <div className="home-fonts">
              UBT EKS
            </div>
          </Link>
          <Link to={`${slugs.book_list_user}/videos`} className="col-4 center" style={{ padding: "20px" }}>
            <img
              className="home-icons"
              src="https://api.widihakwon.com/wp-content/uploads/2024/03/video.png"
            />
            <div className="home-fonts">
              PEMBAHASAN
            </div>
          </Link>
          <Link to={`${slugs.book_list_user}/book`} className="col-4 center">
            <img
              className="home-icons"
              src="https://api.widihakwon.com/wp-content/uploads/2024/03/book.png"
            />
            <div className="home-fonts">
              TEXTBOOK
            </div>
          </Link>
          <Link to={`${slugs.exam}/textbook`} className="col-4 center">
            <img
              className="home-icons"
              src="https://api.widihakwon.com/wp-content/uploads/2024/03/textbook.png"
            />
            <div className="home-fonts">
              LATIHAN MINGGUAN
            </div>
          </Link>
          <Link to={`${slugs.book_list_user}/skill-test`} className="col-4 center">
            <img
              className="home-icons"
              src="https://api.widihakwon.com/wp-content/uploads/2024/03/textbook.png"
            />
            <div className="home-fonts">
              MATERI SKILL TEST
            </div>
          </Link>

        </div>

      </div>
    </div>
  )
}